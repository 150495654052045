import styled from "@emotion/styled";
import Colors from "styles/colors";
import { MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";
import { generateCloudinaryImageUrl } from "services/community/cloudinary-service";
const QUOTE = "/static/img/segments/icons/quote.png";
export const StyledTestimonialsQuote = styled.div`
  .testimonials-text {
    z-index: 1;
    line-height: 38px;
    position: relative;
  }

  margin-top: 40px;
  font-size: 1.375rem;
  line-height: 40px;
  color: ${Colors["text-grey"]};
  position: relative;

  .quote-background {
    background-image: url(${generateCloudinaryImageUrl(QUOTE)});
    background-repeat: no-repeat, no-repeat;
    width: 100px;
    height: 100px;
    background-size: contain, auto;
    position: absolute;
    left: -36px;
    top: 0;
    z-index: 0;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .quote-background {
      left: 16px !important;
    }
  }
`;
