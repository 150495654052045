import styled from "@emotion/styled";
import Colors from "styles/colors";
export const StyledFullTestimonialsQuote = styled.div`
  word-break: break-word;
  max-width: 640px;
  margin: 0 auto;

  .full-testimonials-title {
    letter-spacing: -0.5px;
    color: ${Colors["tuna"]};

    .full-testimonials-dot {
      line-height: 0px;
      font-size: 3.263rem;
    }
  }
`;
