function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import PersonInfoDefaultProps from "segments/desktop/core-components/person-info/default-props";
import TestimonialsQuoteDefaultProps from "segments/desktop/core-components/testimonials-quote/default-props";
import { MD } from "constants/sizes";
export default _object_spread({
    title: "Enjoy working in a platform that adapts to your needs<monday-dot pink />",
    titleSize: MD
}, PersonInfoDefaultProps, TestimonialsQuoteDefaultProps);
