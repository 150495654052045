import styled from "@emotion/styled";
export const StyledTestimonialsTextAndAssetComponent = styled.div`
  .image-wrapper {
    height: 100%;

    .youtube-video-with-poster-component {
      display: flex;
      justify-content: center;
      aspect-ratio: 100/56.25;
      width: 100%;

      .video-component-wrapper {
        height: 100%;
        width: 100%;
        min-width: 300px;

        & * {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
`;
